/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

/* App.css */
body,
html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  width: 100%;
  max-width: 600px;
  /* Sets the maximum width of the app */
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 24px;
  background-color: #0056b3;
}

header {
  background-color: #0073e6;
  color: #ffffff;
  padding: 10px 20px;
  text-align: center;
  font-size: 34px;
}

main {
  flex: 1;
  /* Allows the main content to grow and fill available space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Centers the content vertically in the main area */
  align-items: center;
  /* Centers the content horizontally */
  margin: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  background-color: #0056b3;
}

button {
  background-color: #0073e6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 24px;
}

button:hover {
  background-color: #0056b3;
}

footer {
  text-align: center;
  padding: 20px 0;
  background-color: #222;
  color: white;
}

body {
  width: 100%;
  background-color: #0056b3;
  font-size: 24px;
  /* Adjust this value based on the height of your navbar */
  padding-top: 50px;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
  background: #333;
  color: white;
  padding: 10px 0;
  z-index: 1000;
  /*Make sure it stays on top of other content*/
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}